import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import PortableText from "../../../components/shared/portableText";
import {
  BodySectionWrap,
  BodyTitleContainer,
  BodyContentContainer,
  BodyCopy,
  Accent
} from "./body.stc";

const Body = () => {
  const processBodyPageData = useStaticQuery(graphql`
    query processBodyPageQuery {
      sanityOurProcess {
        headline
        subhead
        accent {
          asset {
            fluid(maxHeight: 800, maxWidth: 1980) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawCopy
      }
    }
  `);

  const {
    headline,
    subhead,
    _rawCopy: copy,
    accent: { asset: { fluid }},
  } = processBodyPageData.sanityOurProcess;


  return (
    <BodySectionWrap>
      <BodyTitleContainer>
        <SectionTitle title={headline} subtitle={subhead} />
      </BodyTitleContainer>
      <BodyContentContainer>
        {copy &&
          <BodyCopy>
            <PortableText blocks={copy} />
          </BodyCopy>
        }
        <Accent
          image={fluid}
        />
      </BodyContentContainer>
    </BodySectionWrap>
  );
};

export default Body;
