import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Hero from '../components/hero';
import Body from '../containers/ourprocess/body';
import Details from '../containers/ourprocess/details';
import Testimonial from '../containers/home/testimonial';
import { graphql } from 'gatsby';

export const ourProcessPageData = graphql`
  query ourProcessPageQuery {
    sanityOurProcess {
      hero {
        title
        subtitle
        image {
          alt
          asset {
            fluid(maxWidth: 1920, maxHeight: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      seo {
        meta_description
        seo_title
      }
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const ourProcessPage = (props) => {
  
  const { data } = props;
  const pageData = data.sanityOurProcess;
  const hero = pageData.hero;
  const seoMeta = pageData.seo;
  const openGraph = pageData.openGraph;

  return (
    <Layout headerLayout={2}>
      <SEO
        title={seoMeta?.seo_title}
        ogtitle={openGraph?.title}
        description={seoMeta?.meta_description}
        ogdescription={openGraph?.description}
        image={openGraph?.image?.asset?.fluid}
        pathname={props.location.pathname}
      />
      <Hero
        title={hero.title}
        subhead={hero.subtitle}
        image={hero.image.asset.fluid}
        alt={hero.image.alt}
      />
      <Body />
      <Details />
      <Testimonial />
    </Layout>
  );
};

export default ourProcessPage;
