import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SectionTitle from '../../../components/shared/section-title';
import {
  SectionWrap,
  TitleContainer,
  ContentContainer,
  DetailItem,
} from './details.stc';

const Details = () => {
  const processDetailsSectionData = useStaticQuery(graphql`
    query processDetailsSectionQuery {
      sanityOurProcess {
        detailsTitle
        detailsSubtitle
        details {
          _key
          title
          feature {
            title
            subtitle
            image {
              asset {
                fluid(maxWidth: 1920, maxHeight: 750) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
            cta {
              copy
              path {
                page {
                  ... on SanityServices {
                    slug {
                      current
                    }
                  }
                  ... on SanityProjects {
                    slug {
                      current
                    }
                  }
                  ... on SanityOurProcess {
                    slug {
                      current
                    }
                  }
                  ... on SanityHome {
                    slug {
                      current
                    }
                  }
                  ... on SanityContact {
                    slug {
                      current
                    }
                  }
                  ... on SanityAbout {
                    slug {
                      current
                    }
                  }
                  ... on SanityProject {
                    slug {
                      current
                    }
                  }
                  ... on SanityService {
                    slug {
                      current
                    }
                  }
                }
              }
            }
          }
        }
        _rawDetails
      }
    }
  `);

  const {
    detailsTitle: title,
    detailsSubtitle: subhead,
    details,
    _rawDetails: copyData
  } = processDetailsSectionData.sanityOurProcess;
  return (
    <SectionWrap>
      <TitleContainer>
        <SectionTitle title={title} subtitle={subhead} />
      </TitleContainer>
      <ContentContainer>
        {details.map((detail, i) => {
          return (
            <DetailItem
              key={detail._key}
              i={i}
              parentTitle={detail.title}
              title={detail.feature.title}
              subtitle={detail.feature.subtitle}
              copy={copyData[i].feature.copy}
              image={detail.feature.image.asset.fluid}
              alt={detail.feature.image.alt}
              ctaText={detail.feature.cta.copy}
              ctaPath={detail.feature.cta.path.page.slug.current}
            />
          );
        })}
      </ContentContainer>
    </SectionWrap>
  );
};

export default Details;
